import { Photo } from './interfaces';

export const hasValidationsWarning = (photo: Photo): boolean => {
	return (
		photo.validationResults?.HAS_DOCUMENT === false ||
		photo.validationResults?.HAS_VEHICLE === false ||
		photo.validationResults?.EXTRACT_PLATE === null ||
		photo.validationResults?.EXTRACT_VIN === null
	);
};

export const dataUrl2Blob = (dataUrl: string): Blob => {
	// Yes. This block was pasted from stackoverflow.
	const arr = dataUrl?.split(',');
	const mime = arr![0]?.match(/:(.*?);/)![1];
	const bstr = atob(arr![1]);
	let n = bstr.length;
	// eslint-disable-next-line no-undef
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new Blob([u8arr], { type: mime });
};

// Returns Photo and index
// TODO: Remove [undefined] posibility. Simplifies !-code later and fixes potential crash.
export const getSelectedPhoto = (
	photos: Photo[],
): [Photo, number] | [undefined, number] => {
	const index = photos.findIndex((p: Photo) => p.selected);
	if (index !== -1) {
		return [photos[index], index];
	}

	return [undefined, -1];
};
