// Road scenes
import CarCounterpartIcon from '/../../assets/images/sketch/car-counterpart.svg';
import CarEnsuredIcon from '/../../assets/images/sketch/car-ensured.svg';
import StopIcon from '/../../assets/images/sketch/stop.svg';
import TrafficLightIcon from '/../../assets/images/sketch/traffic-light.svg';
import NextIcon from '/../../assets/images/sketch/next-page.svg';
import BackIcon from '/../../assets/images/sketch/previous-page.svg';
import NoTurnIcon from '/../../assets/images/sketch/no-turn.svg';
import NoUTurnIcon from '/../../assets/images/sketch/no-u-turn.svg';
import NoEntry from '/../../assets/images/sketch/no-entry.svg';
import NoOvertaking from '/../../assets/images/sketch/no-overtaking.svg';
import ProhibitedIcon from '/../../assets/images/sketch/prohibited.svg';
import GiveWayIcon from '/../../assets/images/sketch/give-way.svg';
import TheRock from '/../../assets/images/sketch/the-rock.svg';
import LineIcon from '/../../assets/images/sketch/line.png';
import LinesIcon from '/../../assets/images/sketch/lines.png';

export const SKETCH_ICONS = {
	INSURED: {
		value: 'insured',
		name: 'Asegurado',
		icon: CarEnsuredIcon,
		iconSymbol: null,
		className: '',
	},
	COUNTERPART: {
		value: 'counterpart',
		name: 'Perjudicado',
		icon: CarCounterpartIcon,
		iconSymbol: null,
		className: '',
	},
	LINES: {
		value: 'lines',
		name: 'Dirección',
		icon: LineIcon,
		iconSymbol: LinesIcon,
		className: 'icon--small',
	},
	STOP: {
		value: 'stop',
		name: 'Alto',
		icon: StopIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	TRAFFIC_LIGHT: {
		value: 'traffic_light',
		name: 'Semáforo',
		icon: TrafficLightIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	NEXT_PAGE: {
		value: 'next',
		name: '',
		icon: NextIcon,
		iconSymbol: null,
		className: 'icon--small icon--rotate-180',
	},
	PREVIOUS_PAGE: {
		value: 'previous',
		name: '',
		icon: BackIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	FIXED_OBJECT: {
		value: 'solid_object',
		name: 'Objeto Fijo',
		icon: TheRock,
		iconSymbol: null,
		className: 'icon--medium',
	},
	GIVE_WAY: {
		value: 'give_way',
		name: 'Ceda',
		icon: GiveWayIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	PROHIBITED: {
		value: 'prohibited',
		name: 'Prohibido',
		icon: ProhibitedIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	NO_TURN: {
		value: 'no_turn',
		name: 'No Virar',
		icon: NoTurnIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	NEXT_PAGE_2: {
		value: 'next',
		name: '',
		icon: NextIcon,
		iconSymbol: null,
		className: 'icon--small icon--rotate-180',
	},
	PREVIOUS_PAGE_2: {
		value: 'previous',
		name: '',
		icon: BackIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	NO_U_TURN: {
		value: 'no_u_turn',
		name: 'No Giro en U',
		icon: NoUTurnIcon,
		iconSymbol: null,
		className: 'icon--small',
	},
	NO_ENTRY: {
		value: 'no_entry',
		name: 'No Hay Paso',
		icon: NoEntry,
		iconSymbol: null,
		className: 'icon--small',
	},
	NO_OVERTAKING: {
		value: 'no_overtaking',
		name: 'No Adelantar',
		icon: NoOvertaking,
		iconSymbol: null,
		className: 'icon--small',
	},
};
