export enum DamageAreas {
	FRONT_END = 'Parte Frontal', // area 1, 2, 14, 15
	RIGHT_SIDE = 'Lateral Derecho', // area 3, 4, 5, 6
	REAR_END = 'Parte Trasera', // area 7, 8, 9, 19
	LEFT_SIDE = 'Lateral Izquierdo', // area 10, 11, 12, 13
	WINDSHIELD = 'Vidrio Frontal', // area 16
	TOP = 'Techo', // area 17
	REAR_WINDOW = 'Vidrio Trasero', // area 18
}

export const getDamageArea = (index: number): DamageAreas => {
	switch (index) {
		case 1:
		case 2:
		case 14:
		case 15:
			return DamageAreas.FRONT_END;
		case 3:
		case 4:
		case 5:
		case 6:
			return DamageAreas.RIGHT_SIDE;
		case 7:
		case 8:
		case 9:
		case 19:
			return DamageAreas.REAR_END;
		case 10:
		case 11:
		case 12:
		case 13:
			return DamageAreas.LEFT_SIDE;
		case 16:
			return DamageAreas.WINDSHIELD;
		case 17:
			return DamageAreas.TOP;
		case 18:
			return DamageAreas.REAR_WINDOW;
		default:
			return DamageAreas.REAR_WINDOW;
	}
};

export const getDamageAreaAsNumber = (area: DamageAreas): number => {
	switch (area) {
		case DamageAreas.LEFT_SIDE:
			return 1;
		case DamageAreas.RIGHT_SIDE:
			return 2;
		case DamageAreas.FRONT_END:
			return 3;
		case DamageAreas.REAR_END:
			return 4;
		case DamageAreas.TOP:
		default:
			return 5;
	}
};
