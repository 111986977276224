import React, { useState } from 'react';
import { SKETCH_ICONS } from '../constants';
import ToolbarSymbol from './Symbol';

interface SketchToolbarProps {
	addIcon: (iconType: string, className: string) => void;
	toggleDraw: (value?: boolean) => void;
}

const SketchToolbar = (props: SketchToolbarProps): JSX.Element => {
	const [page, setPage] = useState(0);

	const PAGE_SIZE = 6;
	const startIndex = page !== 0 ? PAGE_SIZE * page : 0;
	const endIndex = page === 0 ? PAGE_SIZE : PAGE_SIZE * page + PAGE_SIZE;

	const add = (
		icon: string,
		className: string,
		sketchTool: { [key: string]: any },
	): void => {
		if (sketchTool.value === 'next') {
			return setPage(page + 1);
		} else if (sketchTool.value === 'previous') {
			return setPage(page - 1);
		}

		props.addIcon(icon, className);
		props.toggleDraw(false);
	};

	return (
		<div className="sketch-toolbar">
			{Object.values(SKETCH_ICONS)
				.slice(startIndex, endIndex)
				.map((sketchTool) => {
					return (
						<div key={sketchTool.value} className="symbol-container">
							<ToolbarSymbol
								className={sketchTool.className}
								addIcon={(icon, className): void =>
									add(icon, className, sketchTool)
								}
								icon={sketchTool.icon}
								symbol={sketchTool.iconSymbol || sketchTool.icon}
								alt={sketchTool.value}
							/>
							<span className="symbol-name">{sketchTool.name}</span>
						</div>
					);
				})}
		</div>
	);
};

export default SketchToolbar;
