import React, { ReactElement } from 'react';
import { ReactComponent as AlertIcon } from './assets/imgs/warning-alert.svg';
import { ValidationError } from './types';

import './validationWarning.scss';

interface WarningProps {
	title: string;
	message: string;
	handleClick?: () => void;
	hideButton?: boolean;
	note?: string;
}

const Warning = ({
	title,
	message,
	hideButton,
	note,
	handleClick = (): null => null,
}: WarningProps): ReactElement => {
	return (
		<div className="warning">
			<div className="warning__container">
				<AlertIcon />
				<h2>{title}</h2>
				<p>{message}</p>
				<p>{note}</p>
				{!hideButton && (
					<button className="button" onClick={handleClick}>
						Tomar nuevamente
					</button>
				)}
			</div>
		</div>
	);
};

export default function ValidationWarning({
	validationError,
	handleWarningButton,
}: {
	validationError: any;
	handleWarningButton: any;
}): ReactElement {
	return (
		<div className="warning">
			{validationError === ValidationError.NO_DOCUMENT && (
				<Warning
					title="Documento no detectado"
					message="Parece que la foto que tomaste no corresponde a un documento o se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
			{validationError === ValidationError.NO_VEHICLE && (
				<Warning
					title="Vehículo no detectado"
					message="Parece que la foto que tomaste no corresponde a un vehículo o se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
			{validationError === ValidationError.NO_PLATE && (
				<Warning
					title="Placa no detectada"
					message="Parece que la foto que tomaste no corresponde a una placa o se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
			{validationError === ValidationError.NO_VIN && (
				<Warning
					title="VIN no detectada"
					message="Parece que la foto que tomaste no corresponde a un VIN o se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
			{validationError === ValidationError.IS_BLURRY && (
				<Warning
					title="Foto borrosa"
					message="Parece que la foto que tomaste se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
			{validationError === ValidationError.NO_ODOMETER && (
				<Warning
					title="Odómetro no detectado"
					message="Parece que la foto que tomaste no corresponde a un tacómetro de un vehículo o se encuentra borrosa"
					handleClick={handleWarningButton}
				/>
			)}
		</div>
	);
}
