export enum DamageAreas {
	FRONT_END = 'Parte Frontal', // area 1, 2, 14, 15
	RIGHT_SIDE = 'Lateral Derecho', // area 3, 4, 5, 6
	REAR_END = 'Parte Trasera', // area 7, 8, 9, 19
	LEFT_SIDE = 'Lateral Izquierdo', // area 10, 11, 12, 13
	TOP = 'Techo', // area 16, 17, 18
}

export enum PhotoSection {
	PANORAMIC = 1, // Panoramica
	INSURED_VEHICLE = 2, // VehiculoAsegurado
	INSURED_DOCUMENTS = 3, // DocumentosAsegurado
	COUNTERPARTS = 4, // Terceros
	COUNTERPARTS_DOCUMENTS = 5, // DocumentosTerceros
	OTHERS = 6, // Otras
	INSURED_LICENSE = 20,
	INSURED_VEHICLE_LICENSE = 21,
	INSURED_ODOMETER = 22,
	INSURED_MARBETE = 23,
	INSURED_VIN = 24,
	INSURED_TABLILLA = 25,
	INSURED_VEH_PHOTOS = 26,
	INSURED_VEH_DAMAGE_PHOTOS = 27,
	INSURED_VEH_PANORAMIC = 28,
	INSURED_OTHERS_DOCS = 29,
	INJURED_LICENSE = 8,
	INJURED_VEHICLE_LICENSE = 9,
	INJURED_ODOMETER = 10,
	INJURED_MARBETE = 11,
	INJURED_VIN = 12,
	INJURED_TABLILLA = 13,
	INJURED_VEH_PHOTOS = 14,
	INJURED_VEH_DAMAGE_PHOTOS = 15,
	INJURED_VEH_PANORAMIC = 16,
	INJURED_OTHERS_DOCS = 17,
}

export enum Validation {
	HAS_DOCUMENT = 'HAS_DOCUMENT',
	HAS_VEHICLE = 'HAS_VEHICLE',
	EXTRACT_PLATE = 'EXTRACT_PLATE',
	EXTRACT_VIN = 'EXTRACT_VIN',
}

interface SimplePhoto {
	dataUrl?: string;
	awsUrl?: string;
}

export interface Photo extends SimplePhoto {
	id: string;
	section: PhotoSection;
	deleted?: boolean;
	placeholder?: string;
	selected?: boolean;
	description: string;
	required: boolean;
	validations: Validation[]; // the AI validations we should run for this picture
	validationResults: {
		// the results from Heimdall as they arrive
		[Validation.HAS_VEHICLE]?: boolean;
		[Validation.HAS_DOCUMENT]?: boolean;
		[Validation.EXTRACT_PLATE]?: string;
		[Validation.EXTRACT_VIN]?: string;
	};
}
