import { Autocomplete } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import pin from '/../../assets/images/location/pin.png';
import searchIcon from '/../../assets/images/location/search.png';
import './Map.scss';

interface Props {
	currentAddress: string;
	searchOpen: boolean;
	gmapsLoaded: boolean;
	onLocationRetrieved: (lat: number, lng: number) => void;
	onFocus: () => void;
	onPlaceChanged: () => void;
}

const LocationSearch = ({
	currentAddress,
	searchOpen,
	gmapsLoaded,
	onLocationRetrieved,
	onPlaceChanged,
	onFocus,
}: Props): JSX.Element => {
	const [autocomplete, setAutocomplete] = useState<any>(null);
	const [situationAddress, setSituationAddress] = useState('');
	const onLoad = (loaded: any): void => {
		setAutocomplete(loaded);
	};
	const handleOnPlaceChanged = (): void => {
		if (autocomplete === null) {
			// eslint-disable-next-line no-console
			console.log('Autocomplete is not loaded yet!');

			return;
		}

		try {
			const place = autocomplete.getPlace();
			const location = place.geometry.location;
			onLocationRetrieved(location.lat(), location.lng());
			setSituationAddress('');
			onPlaceChanged();
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};
	const handleOnFocus = (): void => {
		if (!searchOpen) {
			onFocus();
		}
	};

	// HACK: On safari we have to scroll to top, because keyboard doesnt
	// 	resize window. More info: https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
	useEffect(() => {
		if (searchOpen) {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 100);
		}
	}, [searchOpen]);

	if (!gmapsLoaded) {
		return <React.Fragment />;
	}
	const addressToShow = searchOpen ? situationAddress : currentAddress;

	return (
		<Autocomplete
			onLoad={onLoad}
			onPlaceChanged={handleOnPlaceChanged}
			options={{
				componentRestrictions: { country: ['pr', 'pa', 'cr'] },
			}}
		>
			<div className="location-search">
				<div className="input-container">
					<img src={pin} className="pin" alt="pin" />
					<input
						type="text"
						placeholder="Dirección del accidente"
						onFocus={handleOnFocus}
						value={addressToShow}
						onChange={(event): void => setSituationAddress(event.target.value)}
					/>
					<img src={searchIcon} className="lupa" alt="lupa" />
				</div>
			</div>
		</Autocomplete>
	);
};

export default LocationSearch;
