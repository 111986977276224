import React from 'react';
import clsx from 'clsx';
import './styles.module.scss';

interface DamageCheckProps {
	areaId: number;
	selected: boolean;
	disabled: boolean;
	onClick: () => void;
}

const DamageCheck = (props: DamageCheckProps): JSX.Element => {
	return (
		<button
			data-testid="damageCheck"
			className={clsx('damageCheck', {
				'damageCheck--disabled': props.disabled,
				'damageCheck--selected': props.selected,
			})}
			onClick={props.onClick}
		>
			{props.areaId}
		</button>
	);
};

export default DamageCheck;
