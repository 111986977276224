import { GoogleMap } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import gpsOffIcon from '/../../assets/images/location/gps-off.svg';
import markerIcon from '/../../assets/images/location/marker.png';
// eslint-disable-next-line no-unused-vars
import { Location } from './interfaces';
import './Map.scss';

const getLocation = (
	geo: Geolocation,
	onLocationRetrieved: (lat: number, lng: number) => void,
): void => {
	if (!geo) {
		return;
	}
	geo.getCurrentPosition(
		({ coords: { latitude: lat, longitude: lng } }) => {
			onLocationRetrieved(lat, lng);
		},
		(error) => {
			console.error(error);
		},
	);
};

interface Props {
	gmapsLoaded: boolean; // if google maps library is loaded.
	location: Location;
	markerIcon?: string;
	onLocationRetrieved: (lat: number, lng: number) => void;
	onOtherLocationClick?: () => void;
}

const LocationMap = ({
	location,
	onLocationRetrieved,
	onOtherLocationClick,
	gmapsLoaded,
}: Props): JSX.Element => {
	const { coords, newLocation } = location;
	const [geo] = useState(navigator.geolocation);

	useEffect(() => {
		if (!newLocation) {
			getLocation(geo, onLocationRetrieved);
		}
	}, [newLocation, geo, onLocationRetrieved]);

	// Use internal UI state so as to de-bounce the center changes, and only
	//  send updates to global state onDragEnd, while keeping marker centered.
	const mapRef = useRef<any>(null);
	const onLoad = (map: any): void => {
		mapRef.current = map;
	};
	const onDragEnd = (): void => {
		if (mapRef.current === null) {
			return;
		}
		const newCenter = mapRef.current.getCenter().toJSON();
		onLocationRetrieved(newCenter.lat, newCenter.lng);
	};

	return (
		<div className="map" id="map-container">
			{onOtherLocationClick && (
				<div className="map__gpsOff" onClick={onOtherLocationClick}>
					No funciona ubicación
					<img src={gpsOffIcon} className="map__gpsOffIcon" />
				</div>
			)}
			{gmapsLoaded && (
				<GoogleMap
					id="map"
					zoom={19}
					center={coords}
					options={{
						fullscreenControl: false,
						disableDefaultUI: true,
						clickableIcons: false,
						gestureHandling: 'auto',
					}}
					onLoad={onLoad}
					onDragEnd={onDragEnd}
				/>
			)}
			<img className="center-pin" src={markerIcon || markerIcon} alt="pin" />
		</div>
	);
};

export default LocationMap;
