import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GMAPS_KEY || '');

// Tiny wrapper/file to provide single entry-point to the .setApiKey call above.
export async function reverseGeocode(
	lat: string,
	lng: string,
): Promise<string> {
	return Geocode.fromLatLng(lat, lng).then(findBestAddress);
}

// Wrapper to provide single entry-point to the addres areas.
export async function reverseGeoArea(
	lat: string,
	lng: string,
): Promise<{ neigh: string; city: string; state: string; country: string }> {
	return Geocode.fromLatLng(lat, lng).then(findAreaLevels);
}

// eslint-disable-next-line camelcase
function findBestAddress({
	results,
}: {
	// eslint-disable-next-line camelcase
	results: { formatted_address: string }[];
}): string {
	return results[0].formatted_address;
}

// eslint-disable-next-line camelcase
function findAreaLevels({
	results,
}: {
	// eslint-disable-next-line camelcase
	results: { address_components: any }[];
}): { neigh: string; city: string; state: string; country: string } {
	let neighborhood, cityArea, stateArea, country;
	for (let i = 0; i < results[0].address_components.length; i++) {
		for (let j = 0; j < results[0].address_components[i].types.length; j++) {
			switch (results[0].address_components[i].types[j]) {
				case 'neighborhood':
					neighborhood = results[0].address_components[i].short_name;
					break;
				case 'locality':
					cityArea = results[0].address_components[i].long_name;
					break;
				case 'administrative_area_level_1':
					stateArea = results[0].address_components[i].long_name;
					break;
				case 'country':
					country = results[0].address_components[i].long_name;
					break;
			}
		}
	}

	return {
		neigh:
			neighborhood && String(neighborhood).length > 0 ? neighborhood : cityArea,
		city: cityArea,
		state: stateArea,
		country: country,
	};
}
