import React, { FC, useEffect, useState } from 'react';

/**
 * Taked from https://github.com/fbaiodias/react-video-recorder/blob/master/src/defaults/render-actions.js, and changed according to our needs
 */

const pad = (unit: number) => {
	const str = '' + unit;
	const pad = '00';

	return pad.substring(0, pad.length - str.length) + str;
};

const getState = (seconds: number) => {
	const minutes = Math.floor(seconds / 60);

	const humanTime =
		minutes !== 0
			? `${minutes}:${pad(seconds - minutes * 60)}`
			: `${seconds - minutes * 60}s`;

	return {
		seconds: seconds,
		human: humanTime,
	};
};

const VideoTimer: FC<{ timeLimit?: number }> = ({ timeLimit }) => {
	const nextSeconds = timeLimit ? timeLimit / 1000 : 0;
	const [state, setState] = useState(getState(nextSeconds));

	useEffect(() => {
		const timer = setInterval(() => {
			const { seconds } = state;
			const nextSeconds = timeLimit ? seconds - 1 : seconds + 1;
			setState(getState(nextSeconds));
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [state.seconds]);

	return (
		<div className="text">
			<div className="rec-icon" />
			{state.human || '0:00'}
		</div>
	);
};

export default VideoTimer;
