import React from 'react';

interface ToolbarSymbolProps {
	icon: string;
	symbol: string;
	alt: string;
	className: string;
	addIcon: (iconType: string, className: string) => void;
}

const ToolbarSymbol = (props: ToolbarSymbolProps): JSX.Element => {
	return (
		<img
			id={props.symbol}
			onClick={(): void => props.addIcon(props.icon, props.className)}
			className="symbol"
			src={props.symbol}
			alt={props.alt}
		/>
	);
};

export default ToolbarSymbol;
