import React, { useState } from 'react';
import clsx from 'clsx';

import VehicleDiagram from '/../../assets/images/vehicle-diagram.png';
import DamageCheck from './damage-check';
import DamageWindow from './damage-window';
import { Damage, Damages } from './interfaces';
import './styles.module.scss';

const AREAS = new Array(19).fill(0).map((_, i) => i + 1);

interface Props {
	damages: Damages;
	className?: string;
	showOtherOption?: boolean;
	showNotesInput?: boolean;
	version?: 1 | 2;
	damageList?: Damage[];
	notes?: string;
	notesPlaceholder?: string;
	otherOptionPlaceholder?: string;
	readonly?: boolean;
	onChangeDamages: (newDamages: Damages, newDamageList?: Damage[]) => void;
	onNotesChange?: (notes: string) => void;
}

const VehicleDamages = ({
	className = '',
	damages,
	showOtherOption,
	showNotesInput,
	version,
	damageList,
	notes,
	notesPlaceholder,
	otherOptionPlaceholder,
	onChangeDamages,
	onNotesChange,
	readonly,
}: Props): JSX.Element => {
	const [damageWindowId, setDamageWindowId] = useState<number | null>(null);

	const selected = (areaId: number): void => {
		if (!readonly) {
			setDamageWindowId(areaId);
		}
	};

	const cancel = (): void => {
		setDamageWindowId(null); // close modal
	};

	const onConfirm = (
		areaId: number,
		damageTypes: string[],
		damage: Damage,
	): void => {
		const newDamageList: Damage[] = [...(damageList || [])];

		const index = newDamageList.findIndex(
			(d) => d.damageAreaId === damage.damageAreaId,
		);
		if (
			!damage.scratched &&
			!damage.dented &&
			!damage.mechanicalDamage &&
			!damage.broken &&
			!(damage.otherDamages && damage.otherDamages.trim().length)
		) {
			if (index !== -1) {
				newDamageList.splice(index, 1);
			}
		} else if (index !== -1) {
			newDamageList[index] = damage;
		} else {
			newDamageList.push(damage);
		}

		let newDamages = { ...damages };
		if (!damageTypes.length) {
			delete newDamages[areaId];
		} else {
			newDamages = {
				...newDamages,
				[areaId]: damageTypes,
			};
		}

		onChangeDamages(newDamages, newDamageList);
		setDamageWindowId(null);
	};

	return (
		<div className={clsx('vehicleDamages', className)}>
			<img src={VehicleDiagram} alt="" className="vehicleDamages__carImage" />
			<div className="vehicleDamages__checks">
				{AREAS.map((areaId: number) => (
					<DamageCheck
						key={areaId}
						areaId={areaId}
						selected={Boolean(damages[areaId])}
						onClick={(): void => selected(areaId)}
						disabled={readonly || false}
					/>
				))}
			</div>
			{showNotesInput && (
				<textarea
					name="notes"
					placeholder={notesPlaceholder || 'Notas'}
					id="damages-notes"
					rows={10}
					onChange={({ target: { value } }) => {
						if (onNotesChange) {
							onNotesChange(value);
						}
					}}
					defaultValue={notes}
					readOnly={readonly}
				/>
			)}
			{damageWindowId !== null && (
				<DamageWindow
					show={damageWindowId !== null}
					areaId={damageWindowId || 0}
					damageTypes={damageWindowId ? damages[damageWindowId] : []}
					damage={
						damageList?.find((d) => d.damageAreaId === damageWindowId) ||
						({} as Damage)
					}
					close={cancel}
					onConfirm={onConfirm}
					showOtherOption={showOtherOption}
					otherOptionPlaceholder={otherOptionPlaceholder}
					version={version}
				/>
			)}
		</div>
	);
};

export default VehicleDamages;
