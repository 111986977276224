/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { animated, to, useSpring } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { IconPosition, SketchActionType, SketchContext } from '../interfaces';
import { Context } from '../SketchContext';

const BOUND_PADDING = 32;
const WIDTH_OFFSET = 40;
const HEIGHT_OFFSET = 70;

const calcX = (y: number, ly: number): number =>
	-(y - ly - window.innerHeight / 2) / 20;
const calcY = (x: number, lx: number): number =>
	(x - lx - window.innerWidth / 2) / 20;

document.addEventListener('gesturestart', (e) => e.preventDefault());
document.addEventListener('gesturechange', (e) => e.preventDefault());

interface SketchIconProps {
	index: number;
	iconId: string;
	icon: string;
	position: IconPosition;
	className: string;
}

export const SketchIcon = (props: SketchIconProps): JSX.Element => {
	const sketch = useContext(Context) as SketchContext;
	const {
		dispatch,
		location: {
			mapSize: { height: mapHeight, width: mapWidth },
		},
	} = sketch;
	const domTarget = React.useRef(null);
	const { position } = props;
	const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, set] = useSpring(
		() => ({
			rotateX: position.rotateX || 0,
			rotateY: position.rotateY || 0,
			rotateZ: position.rotateZ || 0,
			scale: position.scale || 1,
			zoom: position.zoom || 0,
			x: position.x || 0,
			y: position.y || 0,
			config: { mass: 5, tension: 350, friction: 40 },
		}),
	);
	const [drag, setDrag] = React.useState(false);
	const [deleting, setDeleting] = React.useState(false);
	const [selectedIcon, setSelectedIcon] = React.useState(false);

	const updateProps = (): void => {
		dispatch({
			type: SketchActionType.SET_SKETCH_ICONS,
			data: {
				id: props.iconId,
				position: {
					x: x.get(),
					y: y.get(),
					rotateX: rotateX.get(),
					rotateY: rotateY.get(),
					rotateZ: rotateZ.get(),
					zoom: zoom.get(),
					scale: scale.get(),
				},
			},
		});
	};

	const updateStatus = (): void => {
		const newStatus = !selectedIcon;
		dispatch({
			type: SketchActionType.SET_SKETCH_SELECTED_ICON,
			data: {
				id: props.iconId,
				isSelected: newStatus,
			},
		});
	};

	const bind = useGesture(
		{
			onDragStart: () => setDrag(true),
			onDrag: ({ offset: [x, y] }) => {
				if (x === 0 || y === 0) {
					return;
				}
				dispatch({
					type: SketchActionType.SET_SKETCH_ROTATE_TRIGGER,
					data: false,
				});
				set({ x, y, rotateX: 0, rotateY: 0, scale: 1, immediate: true });
			},
			onDragEnd: () => {
				setDrag(false);
				if (
					mapWidth! / 2 - WIDTH_OFFSET < x.get() &&
					mapHeight! / 2 - HEIGHT_OFFSET < y.get()
				) {
					setDeleting(true);

					dispatch({
						type: SketchActionType.REMOVE_SKETCH_ICON,
						data: props.iconId,
					});
					setDeleting(false);
				} else if (!deleting) {
					updateProps();
				}
			},
			onPinch: ({ offset: [, a] }) => {
				set({ zoom: 0, rotateZ: a, immediate: true }); // zoom 0 to prevent gesture
				updateProps();
			},
			onMove: ({ xy: [px, py], dragging }) => {
				!dragging &&
					sketch.rotateIcon &&
					set({
						rotateX: calcX(py, y.get()),
						rotateY: calcY(px, x.get()),
						scale: 1.1,
						immediate: true,
					});
				updateProps();
			},
			onHover: ({ hovering }) =>
				!hovering && set({ rotateX: 0, rotateY: 0, scale: 1 }),
		},
		{
			domTarget,
			event: { passive: false },
			drag: {
				bounds: {
					left: mapWidth ? -(mapWidth / 2 - BOUND_PADDING) : 0,
					right: mapWidth ? mapWidth / 2 - BOUND_PADDING : 0,
					top: mapHeight ? -(mapHeight / 2 - BOUND_PADDING) : 0,
					bottom: mapHeight ? mapHeight / 2 - BOUND_PADDING : 0,
				},
			},
		} as any,
	);

	React.useEffect(bind as any, [bind]);

	React.useEffect(() => {
		const currentIndexIconStatus = sketch.iconStatus.findIndex(
			(i) => String(i.id) === props.iconId,
		);
		setSelectedIcon(
			sketch.iconStatus[currentIndexIconStatus]?.isSelected || false,
		);
		if (selectedIcon && sketch.rotateIcon) {
			set({ rotateZ: rotateZ.get() + 30 });
			dispatch({
				type: SketchActionType.SET_SKETCH_ROTATE_TRIGGER,
				data: false,
			});
		}
	}, [
		selectedIcon,
		setSelectedIcon,
		props.index,
		sketch.iconStatus,
		sketch.rotateIcon,
		dispatch,
		set,
		rotateZ,
	]);

	const updateSelectedData = () => {
		dispatch({ type: SketchActionType.SET_SKETCH_ROTATE_TRIGGER, data: false });
		setSelectedIcon(!selectedIcon);
		updateStatus();
	};

	return (
		<animated.div
			hidden={deleting}
			data-testid="sketchIcon"
			ref={domTarget}
			className={`icon ${drag ? 'dragging' : ''} ${props.className}`}
			onClick={() => updateSelectedData()}
			style={{
				touchAction: 'none', // prevents window from scrolling while dragging
				transform: 'perspective(600px)',
				x,
				y,
				scale: to([scale, zoom], (s, z) => s + z),
				rotateX,
				rotateY,
				rotateZ,
				padding: 10, // its kinda a magic number, but makes it easier to pinch icons
			}}
		>
			<img
				src={props.icon}
				alt={props.icon}
				className={`${selectedIcon ? 'selectedIconSketch' : ''}`}
			/>
		</animated.div>
	);
};

export default SketchIcon;
