/* eslint-disable no-unused-vars */
import React from 'react';
import domtoimage from 'dom-to-image-more';
import SketchIcon from './icons/Icon';
import ParkingABackground from '/../../assets/images/sketch/with-back/parking-A.svg';
import ParkingBBackground from '/../../assets/images/sketch/with-back/parking-B.svg';
import RoadABackground from '/../../assets/images/sketch/with-back/road-A-photo.svg';
import RoadBBackground from '/../../assets/images/sketch/with-back/road-B-photo.svg';
import RoadCBackground from '/../../assets/images/sketch/with-back/road-C-photo.svg';
import RoadDBackground from '/../../assets/images/sketch/with-back/road-D-photo.svg';
import RoadEBackground from '/../../assets/images/sketch/with-back/road-E-photo.svg';
import RoadFBackground from '/../../assets/images/sketch/with-back/road-F-photo.svg';
import RoadGBackground from '/../../assets/images/sketch/with-back/road-G-photo.svg';
import RoadHBackground from '/../../assets/images/sketch/with-back/road-H-photo.svg';
import RoadIBackground from '/../../assets/images/sketch/with-back/road-I-photo.svg';
import {
	DispatchFunction,
	IconPosition,
	Icons,
	IconStatus,
	SceneTypes,
	SketchActionType,
} from './interfaces';

export const getSketchBackground = (
	sceneType: string | null,
): string | null => {
	switch (sceneType) {
		case SceneTypes.ROAD_A:
			return RoadABackground;
		case SceneTypes.ROAD_B:
			return RoadBBackground;
		case SceneTypes.ROAD_C:
			return RoadCBackground;
		case SceneTypes.ROAD_D:
			return RoadDBackground;
		case SceneTypes.ROAD_E:
			return RoadEBackground;
		case SceneTypes.ROAD_F:
			return RoadFBackground;
		case SceneTypes.ROAD_G:
			return RoadGBackground;
		case SceneTypes.ROAD_H:
			return RoadHBackground;
		case SceneTypes.ROAD_I:
			return RoadIBackground;
		case SceneTypes.PARKING_A:
			return ParkingABackground;
		case SceneTypes.PARKING_B:
			return ParkingBBackground;
		default:
			return null;
	}
};

export const addIconToMap = (
	icons: JSX.Element[],
	iconType: string,
	setIcons: (newIcons: JSX.Element[]) => void,
	dispatch: DispatchFunction,
	className: string,
): void => {
	if (iconType === 'carAH') {
		if (icons.find((icon: JSX.Element) => icon.props.icon === 'carA')) {
			return;
		}
		iconType = 'carA';
	}
	if (iconType === 'carBH') {
		if (icons.find((icon: JSX.Element) => icon.props.icon === 'carB')) {
			return;
		}
		iconType = 'carB';
	}
	if (iconType === 'lines') {
		iconType = 'line';
	}
	if (iconType === 'stopSign') {
		iconType = 'stopSignSmall';
	}
	if (iconType === 'trafficLights') {
		iconType = 'trafficLightsSmall';
	}
	const iconIndex = icons.length;
	const iconId = String(Date.now());
	icons.push(
		<SketchIcon
			key={iconIndex}
			icon={iconType}
			position={{}}
			iconId={iconId}
			index={icons.length}
			className={className}
		/>,
	);
	setIcons([...icons]);
	dispatch({
		type: SketchActionType.ADD_SKETCH_ICON,
		data: { [iconId]: { iconType, position: {}, className, id: iconId } },
	});
	dispatch({
		type: SketchActionType.SET_SKETCH_SELECTED_ICON,
		data: { id: iconId, isSelected: false },
	});
};

export const sketchToImage = async (
	dispatch: DispatchFunction,
): Promise<string> => {
	const sketch = document.getElementById('sketch-container');
	// eslint-disable-next-line no-console
	console.log('Get Sketch');

	const dataUrl = await domtoimage.toJpeg(sketch!, { quality: 0.25 });

	// eslint-disable-next-line no-console
	console.log('Success dataURL', dataUrl);

	dispatch({ type: SketchActionType.SET_SKETCH, data: { dataUrl } });
	// eslint-disable-next-line no-console
	console.log('Croquis Saved');

	return String(dataUrl).length > 0
		? String(dataUrl).replace('jpeg', 'png')
		: dataUrl;
};

/*
	 ---------------------------------------- Reducer Functions -----------------------------------------------
*/

export const setIconStatus = (
	icons: IconStatus[],
	data: { id: number; isSelected: boolean },
): IconStatus[] => {
	let _icons: IconStatus[] = [];
	if (icons !== undefined) {
		_icons = [...icons];
	}

	const index = icons.findIndex((i) => i.id === data.id);

	if (_icons.length > 0 && _icons[index] !== undefined) {
		_icons.map((i) => {
			i.isSelected = false;

			return i.isSelected;
		});
		_icons[index].isSelected = data.isSelected;
	} else {
		_icons.push(data);
	}

	return _icons;
};

export const setIconId = (data: {
	id: number;
	isSelected: boolean;
}): number | null => {
	if (data.isSelected) {
		return data.id;
	}

	return null;
};

export const setSketchIcons = (
	icons: Icons,
	data: { id: string; position: IconPosition },
): Icons => {
	const { id } = data;
	const _icons = { ...icons };
	const validCoords = data.position.x !== 0 && data.position.y !== 0;
	if (_icons[id] && validCoords) {
		_icons[id].position = data.position;
		if (data.id && !_icons[id].id) {
			_icons[id].id = data.id;
		}
	}

	return _icons;
};

export const removeSketchIcon = (icons: Icons, id: string): Icons => {
	const _icons = { ...icons };
	delete _icons[id];

	return _icons;
};

export const removeIconStatus = (
	icons: IconStatus[],
	id: number,
): IconStatus[] => {
	const _icons = [...icons];
	const index = _icons.findIndex((i) => i.id === id);
	_icons.splice(index, 1);

	return _icons;
};

export const setIconStatusRestore = (icons: IconStatus[]): IconStatus[] => {
	let _icons: IconStatus[] = [];
	if (icons !== undefined) {
		_icons = [...icons];
	}

	if (_icons.length > 0) {
		_icons.map((i) => {
			i.isSelected = false;

			return i.isSelected;
		});
	}

	return _icons;
};
