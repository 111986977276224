import React, { FC } from 'react';
import './VideoRecord.scss';
import VideoRecorder from 'react-video-recorder';
import renderActions from './renderActions';
import { ReactComponent as Error } from '../../assets/images/error.svg';
import dotsLoading from '../../assets/lotties/dots-loading.json';
import { Player } from '@lottiefiles/react-lottie-player';

type VideoRecordProps = {
	onRecordingComplete: (videoBlob: Blob) => void;
	onError: () => void;
};

const CONSTRAINTS = {
	audio: true,
	video: {
		width: { exact: 480, ideal: 480 },
		height: { exact: 640, ideal: 640 },
		aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
		resizeMode: 'crop-and-scale',
		facingMode: 'environment',
	},
};

const ErrorView = () => {
	return (
		<div className="error-view">
			<Error />
			<p>
				<b>¡Oh no!</b>
			</p>
			<p>¡Oh no! Tu navegador no pudo grabar tu video.</p>
			<p>Reinícialo y vuelve a intentarlo.</p>
		</div>
	);
};

const VideoRecord: FC<VideoRecordProps> = ({
	onRecordingComplete,
	onError,
}) => {
	return (
		<div className="record-container">
			<VideoRecorder
				isFlipped={false}
				timeLimit={30 * 1000}
				mimeType="video/webm"
				constraints={CONSTRAINTS}
				onRecordingComplete={onRecordingComplete}
				onError={onError}
				renderActions={renderActions}
				renderLoadingView={() => (
					<div>
						<p>Cargando </p>
						<Player
							autoplay
							loop
							src={dotsLoading}
							style={{
								height: 'auto',
								width: 'auto',
								position: 'absolute',
								top: '54%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
							}}
						/>
					</div>
				)}
				renderUnsupportedView={() => (
					<div>Este navegador no puede grabar video</div>
				)}
				renderErrorView={() => <ErrorView />}
			/>
		</div>
	);
};

export default VideoRecord;
