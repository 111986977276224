import React from 'react';
import arrow from '/../../../assets/images/sketch/arrow.png';
import collision from '/../../../assets/images/sketch/crash.png';
import dragGesture from '/../../../assets/images/sketch/drag.gif';
import rotateGesture2 from '/../../../assets/images/sketch/rotateMessageInfo.png';
import touchGesture from '/../../../assets/images/sketch/touch.gif';
import trashBin from '/../../../assets/images/sketch/trash_bin_bg.svg';
import zoomGesture from '/../../../assets/images/sketch/zoom.gif';

const TITLE = 'Use los íconos para ilustrar el accidente';

export const CollisionPage = (): JSX.Element => {
	return (
		<div className="collision-page">
			<h4>{TITLE}</h4>
			<img src={collision} alt="collision" className="action-gif" />
			<p>
				Complete diagrama mostrando las posiciones de los vehículos implicados.
				Indique el punto de contacto.
			</p>
		</div>
	);
};
export const TouchGesturePage = (): JSX.Element => {
	return (
		<div className="touch-gesture-page">
			<h4>{TITLE}</h4>
			<img src={touchGesture} alt="touch" className="action-gif" />
			<p>Oprima ícono para agregarlo a su diagrama.</p>
		</div>
	);
};
export const DragGesturePage = (): JSX.Element => {
	return (
		<div className="drag-gesture-page">
			<h4>{TITLE}</h4>
			<img src={dragGesture} alt="drag and drop" className="action-gif" />
			<p>Presione y mueva ícono para colocarlo en lugar deseado.</p>
		</div>
	);
};
export const ZoomGesturePage = (): JSX.Element => {
	return (
		<div className="zoom-gesture-page">
			<h4>{TITLE}</h4>
			<img src={zoomGesture} alt="zoom" className="action-gif" />
			<p>Utilice dos dedos para enfocar o hacer "zoom".</p>
		</div>
	);
};
export const RotateGesturePage = (): JSX.Element => {
	return (
		<div className="rotate-gesture-page">
			<h4>{TITLE}</h4>
			<img src={rotateGesture2} alt="rotate" className="action-gif" />
			<p>
				Seleccione el icono, use el botón rotar y finalmente decida la posición
				del icono.
			</p>
		</div>
	);
};
export const ArrowsPage = (): JSX.Element => {
	return (
		<div className="arrow-page">
			<h4>{TITLE}</h4>
			<img src={arrow} alt="arrow" className="action-gif" />
			<p>Use flechas para indicar las direcciones que transitaban.</p>
		</div>
	);
};
export const TrashBinPage = (): JSX.Element => {
	return (
		<div className="trash-bin-page">
			<h4>{TITLE}</h4>
			<img src={trashBin} alt="trash bin" className="action-gif" />
			<p>Para borrar íconos, presione y mueva hacia el ícono del basurero.</p>
		</div>
	);
};
