import localforage from 'localforage';
import React, { createContext, useEffect, useReducer, useRef } from 'react';
// eslint-disable-next-line no-unused-vars
import { Action, SketchActionType, SketchContext } from './interfaces';
import { reducer } from './sketchReducer';

export const getStateBackup = async (): Promise<SketchContext> =>
	JSON.parse((await localforage.getItem('_sketchState')) || '{}');

export const saveStateBackup = async (
	state: SketchContext,
): Promise<string> => {
	try {
		return await localforage.setItem('_sketchState', JSON.stringify(state));
	} catch (error) {
		console.error(
			'Error saving state. Ignoring since its a backup anyways',
			error,
		);

		return '';
	}
};

const cleanState = {
	dataUrl: null,
	html: null,
	icons: [],
	infoWindowShown: false,
	rotateIcon: false,
	iconStatus: [],
	iconId: null,
	scenePhoto: null,
	draw: { dataUrl: '', points: '' },
	location: { mapSize: { height: 0, width: 0 } },
};

export const Context = createContext({});

export const SketchContextProvider = (props: {
	state: any;
	children: any;
}): JSX.Element => {
	return (
		<Context.Provider value={props.state}>{props.children}</Context.Provider>
	);
};

const SketchState = (props: any): JSX.Element => {
	const dispatch = (action: Action): void => _dispatch(action);

	const data: SketchContext = {
		dispatch,
		...(cleanState as any),
	};
	const [state, _dispatch] = useReducer(reducer, data);

	const hasHydrated = useRef(false); // used to avoid saving state before first read on page load

	useEffect(() => {
		// Try to rehydrate state from localstorage.
		(async (): Promise<void> => {
			const stateBackup = await getStateBackup();
			dispatch({
				type: SketchActionType.HYDRATE_STATE,
				data: {
					...stateBackup,
				},
			});
			hasHydrated.current = true;
		})();
	}, []);
	useEffect(() => {
		if (hasHydrated.current) {
			saveStateBackup(state);
		}
	}, [state]);

	return (
		<SketchContextProvider state={state}>
			{props.children}
		</SketchContextProvider>
	);
};

export const { Consumer } = Context;

export default SketchState;
