import { BrowserQRCodeReader, BrowserPDF417Reader } from '@zxing/browser';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CountryCode } from '../../enums';
dayjs.extend(customParseFormat);

interface Client {
	id: string;
	licenseNumber: string;
	firstName: string;
	lastName: string;
	address: string;
	birthDate: string;
	expirationDate: string;
	licenseType: string;
	fullName: string;
}

const FIELDS = {
	[CountryCode.PAN]: {
		QR: {
			ID: 0,
			FIRSTNAME: 1,
			LASTNAME: 2,
			ADDRESS: 5,
			BIRTH_DATE: 6,
			EXPIRATION: 15,
			LICENSE_NUMBER: 16,
		},
		PDF417: {
			ID: 0,
			LICENSE_NUMBER: 1,
			FIRSTNAME: 2,
			LASTNAME: 3,
			ADDRESS: 5,
			BIRTH_DATE: 8,
			EXPIRATION: 9,
			LICENSE_TYPE: 11,
		},
	},
	[CountryCode.PRI]: {
		QR: {
			ID: 0,
			FIRSTNAME: 1,
			LASTNAME: 2,
			ADDRESS: 5,
			BIRTH_DATE: 6,
			EXPIRATION: 15,
			LICENSE_NUMBER: 16,
		},
		PDF417: {
			ID: 0,
			LICENSE_NUMBER: 1,
			FIRSTNAME: 2,
			LASTNAME: 3,
			ADDRESS: 5,
			BIRTH_DATE: 8,
			EXPIRATION: 9,
			LICENSE_TYPE: 11,
		},
	},
	[CountryCode.CRC]: {
		QR: {
			ID: 0,
			FIRSTNAME: 1,
			LASTNAME: 2,
			ADDRESS: 5,
			BIRTH_DATE: 6,
			EXPIRATION: 15,
			LICENSE_NUMBER: 16,
		},
		PDF417: {
			ID: 0,
			LICENSE_NUMBER: 1,
			FIRSTNAME: 2,
			LASTNAME: 3,
			ADDRESS: 5,
			BIRTH_DATE: 8,
			EXPIRATION: 9,
			LICENSE_TYPE: 11,
		},
	},
	[CountryCode.COL]: {
		QR: {
			ID: 0,
			FIRSTNAME: 1,
			LASTNAME: 2,
			ADDRESS: 5,
			BIRTH_DATE: 6,
			EXPIRATION: 15,
			LICENSE_NUMBER: 16,
		},
		PDF417: {
			ID: 0,
			LICENSE_NUMBER: 1,
			FIRSTNAME: 2,
			LASTNAME: 3,
			ADDRESS: 5,
			BIRTH_DATE: 8,
			EXPIRATION: 9,
			LICENSE_TYPE: 11,
		},
	},
};

const parseDriverLicenseQR = (data: string, countryCode: string): Client => {
	const arrData = data.split('|');

	const client: Client = {
		// @ts-ignore
		id: arrData[FIELDS[countryCode].QR.ID],
		// @ts-ignore
		licenseNumber: arrData[FIELDS[countryCode].QR.LICENSE_NUMBER],
		// @ts-ignore
		firstName: arrData[FIELDS[countryCode].QR.FIRSTNAME],
		// @ts-ignore
		lastName: arrData[FIELDS[countryCode].QR.LASTNAME],
		// @ts-ignore
		address: arrData[FIELDS[countryCode].QR.ADDRESS],
		birthDate: dayjs(
			// @ts-ignore
			arrData[FIELDS[countryCode].QR.BIRTH_DATE],
			'DD-MM-YYYY',
		).format('YYYY-MM-DD'),
		expirationDate: dayjs(
			// @ts-ignore
			arrData[FIELDS[countryCode].QR.EXPIRATION],
			'DD-MM-YYYY',
		).format('YYYY-MM-DD'),
		licenseType: '',
		// @ts-ignore
		fullName: `${arrData[FIELDS[countryCode].QR.FIRSTNAME]} ${
			// @ts-ignore
			arrData[FIELDS[countryCode].QR.LASTNAME]
		}`,
	};

	return client;
};

const parseDriverLicensePDF417 = (
	data: string,
	countryCode: string,
): Client => {
	const arrData = data.split('|');
	const client: Client = {
		// @ts-ignore
		id: arrData[FIELDS[countryCode].PDF417.ID],
		// @ts-ignore
		licenseNumber: arrData[FIELDS[countryCode].PDF417.LICENSE_NUMBER],
		// @ts-ignore
		firstName: arrData[FIELDS[countryCode].PDF417.FIRSTNAME],
		// @ts-ignore
		lastName: arrData[FIELDS[countryCode].PDF417.LASTNAME],
		// @ts-ignore
		address: arrData[FIELDS[countryCode].PDF417.ADDRESS],
		birthDate: dayjs(
			// @ts-ignore
			arrData[FIELDS[countryCode].PDF417.BIRTH_DATE],
			'DD/MM/YYYY',
		).format('YYYY-MM-DD'),
		expirationDate: dayjs(
			// @ts-ignore
			arrData[FIELDS[countryCode].PDF417.EXPIRATION],
			'DD/MM/YYYY',
		).format('YYYY-MM-DD'),
		// @ts-ignore
		licenseType: arrData[FIELDS[countryCode].PDF417.LICENSE_TYPE],
		// @ts-ignore
		fullName: `${arrData[FIELDS[countryCode].PDF417.FIRSTNAME]} ${
			// @ts-ignore
			arrData[FIELDS[countryCode].PDF417.LASTNAME]
		}`,
	};

	return client;
};

const createImgTemp = (url: string): HTMLImageElement => {
	const img = window.document.createElement('img');
	img.src = url;
	img.id = 'tmpImage';
	img.crossOrigin = 'anonymous';
	img.style.display = 'none';
	document.body.append(img);

	return img;
};

const removeImgTemp = (id: string) => {
	const element = window.document.getElementById(id);
	element?.remove();
};

export const getDataFromImageUrlQR = async (
	url: string,
	countryCode: string,
) => {
	removeImgTemp('tmpImage');
	const reader = new BrowserQRCodeReader();
	const img = createImgTemp(url);
	const result = await reader.decodeFromImageElement(img.id);
	const clientData = parseDriverLicenseQR(result.getText(), countryCode);

	return clientData;
};

export const getDataFromImageUrlPDF417 = async (
	url: string,
	countryCode: string,
) => {
	removeImgTemp('tmpImage');
	const reader = new BrowserPDF417Reader();
	const img = createImgTemp(url);
	const result = await reader.decodeFromImageElement(img.id);
	const clientData = parseDriverLicensePDF417(result.getText(), countryCode);

	return clientData;
};
