export interface Damages {
	[key: number]: string[]; // area number => damage labels
}

export interface Damage {
	damageId: number;
	eventId: number;
	damageAreaId: number;
	scratched: boolean;
	dented: boolean;
	mechanicalDamage: boolean;
	broken: boolean;
	otherDamages: string;
	description: string;
}

export enum DamageTypes {
	SCRATCH = 'Rayadura',
	DENT = 'Abolladura',
	MECHANICAL_DAMAGE = 'Daños mecánicos',
	BROKEN = 'Quebrado/Rajado',
}
