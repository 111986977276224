import { useEffect, useState } from 'react';

/**
 * Returns the current window size whenever it changes.
 */
export const useWindowResize = (): { width?: number; height?: number } => {
	const getSize = (): { width?: number; height?: number } => {
		return {
			width: window?.innerWidth,
			height: window?.innerHeight,
		};
	};

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect((): any => {
		if (typeof window !== 'object') {
			return false;
		}
		const handleResize = (): void => setWindowSize(getSize());
		window.addEventListener('resize', handleResize);

		return (): any => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return windowSize;
};
