/* eslint-disable no-unused-vars */
import { Location } from '../location/interfaces';

export enum SketchActionType {
	SET_ICON = 'SET_ICON',
	HYDRATE_STATE = 'HYDRATE_STATE',
	SET_DRAW_DATA = 'SET_DRAW_DATA',
	SET_SKETCH_ROTATE_TRIGGER = 'SET_SKETCH_ROTATE_TRIGGER',
	SET_MAP_SIZE = 'SET_MAP_SIZE',
	ADD_SKETCH_ICON = 'ADD_SKETCH_ICON',
	SET_SKETCH_SELECTED_ICON = 'SET_SKETCH_SELECTED_ICON',
	SET_SKETCH = 'SET_SKETCH',
	SET_SKETCH_INFO_WINDOW_SHOWN = 'SET_SKETCH_INFO_WINDOW_SHOWN',
	SET_SKETCH_ICONS = 'SET_SKETCH_ICONS',
	REMOVE_SKETCH_ICON = 'REMOVE_SKETCH_ICON',
	SET_SKETCH_UNSELECTED_ICONS = 'SET_SKETCH_UNSELECTED_ICONS',
}

export interface Action<T = any> {
	type: SketchActionType;
	data?: T;
}

export type DispatchFunction = (action: Action) => void;

export interface Coordinates {
	lat: number;
	lng: number;
}

export interface IconPosition {
	x?: number;
	y?: number;
	rotateX?: number;
	rotateY?: number;
	rotateZ?: number;
	zoom?: number;
	scale?: number;
}

export interface IconProps {
	id: string;
	iconType: string;
	position: IconPosition;
	className: string;
}
export interface IconStatus {
	id: number;
	isSelected: boolean | undefined;
}

export interface Icons {
	[id: string]: IconProps;
}

export interface SketchContext {
	dataUrl: string | null;
	html: string | null;
	icons: Icons;
	infoWindowShown: boolean;
	rotateIcon: boolean;
	iconStatus: IconStatus[];
	iconId: number | null;
	scenePhoto: string | null;
	draw: { dataUrl: string; points: string };
	location: Location;
	dispatch: DispatchFunction;
}

export enum SceneTypes {
	MAP = 'map',
	ROAD_A = 'roadA',
	ROAD_B = 'roadB',
	ROAD_C = 'roadC',
	ROAD_D = 'roadD',
	ROAD_E = 'roadE',
	ROAD_F = 'roadF',
	ROAD_G = 'roadG',
	ROAD_H = 'roadH',
	ROAD_I = 'roadI',
	PARKING_A = 'parkingA',
	PARKING_B = 'parkingB',
}
