import { Photo, PhotoSection, Validation } from './interfaces';
import Panoramic from '/../../assets/images/camera/panoramic.svg';
import CarPassengerSide from '/../../assets/images/camera/car-passenger-side.png';
import CarDriverSide from '/../../assets/images/camera/car-driver-side.png';

import CarBack from '/../../assets/images/camera/car-back.png';
import CarFront from '/../../assets/images/camera/car-front.png';
import Vin from '/../../assets/images/camera/vin.svg';
import Odometer from '/../../assets/images/camera/odometer.svg';
import Plate from '/../../assets/images/camera/plate.svg';
import Damage from '/../../assets/images/camera/damage.svg';
import License from '/../../assets/images/camera/license.svg';
import VehicleRegistration from '/../../assets/images/camera/ruc.png';
import Identification from '/../../assets/images/camera/id.svg';
import Fud from '/../../assets/images/camera/fud.svg';
import Other from '/../../assets/images/camera/other-photo.png';

export const defaultPhotos: Photo[] = [
	{
		id: 'panoramic_photo_1',
		section: PhotoSection.PANORAMIC,
		placeholder: Panoramic,
		selected: true,
		description: 'Foto Panorámica 1',
		required: true,
		validations: [],
		validationResults: {},
	},
	{
		id: 'panoramic_photo_2',
		section: PhotoSection.PANORAMIC,
		placeholder: Panoramic,
		selected: false,
		description: 'Foto Panorámica 2',
		required: true,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_vehicle_passenger_side',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: CarPassengerSide,
		selected: false,
		description: 'Foto Lateral Derecha del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'insured_vehicle_driver_side',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: CarDriverSide,
		selected: false,
		description: 'Foto Lateral Izquierda del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'insured_vehicle_back',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: CarBack,
		selected: false,
		description: 'Foto Trasera del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'insured_vehicle_front',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: CarFront,
		selected: false,
		description: 'Foto Delantera del Vehículo de Asegurado',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'insured_vin',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Vin,
		selected: false,
		description: 'Foto Chasis (VIN Number) del Vehículo de Asegurado',
		required: true,
		validations: [Validation.EXTRACT_VIN],
		validationResults: {},
	},
	{
		id: 'insured_odometer',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Odometer,
		selected: false,
		description: 'Foto del Odómetro del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_plate',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Plate,
		selected: false,
		description: 'Foto de Placa del Vehículo de Asegurado',
		required: true,
		validations: [Validation.EXTRACT_PLATE],
		validationResults: {},
	},
	{
		id: 'insured_damage_1',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Damage,
		selected: false,
		description: 'Foto de Daños 1 del Vehículo de Asegurado',
		required: true,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_damage_2',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Damage,
		selected: false,
		description: 'Foto de Daños 2 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_damage_3',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Damage,
		selected: false,
		description: 'Foto de Daños 3 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_damage_4',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Damage,
		selected: false,
		description: 'Foto de Daños 4 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_damage_5',
		section: PhotoSection.INSURED_VEHICLE,
		placeholder: Damage,
		selected: false,
		description: 'Foto de Daños 5 del Vehículo de Asegurado',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'insured_license',
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: License,
		selected: false,
		description: 'Foto de Licencia del Asegurado',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'insured_registration',
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: VehicleRegistration,
		selected: false,
		description: 'Foto de Registro Único Vehícular del Asegurado',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'insured_id',
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: Identification,
		selected: false,
		description: 'Foto de Cédula del Asegurado',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'insured_fud',
		section: PhotoSection.INSURED_DOCUMENTS,
		placeholder: Fud,
		selected: false,
		description: 'Foto de FUD',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'counterpart_vehicle_passenger_side',
		section: PhotoSection.COUNTERPARTS,
		placeholder: CarPassengerSide,
		selected: false,
		description: 'Foto Lateral Derecha del Vehículo de Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'counterpart_vehicle_driver_side',
		section: PhotoSection.COUNTERPARTS,
		placeholder: CarDriverSide,
		selected: false,
		description: 'Foto Lateral Izquierda del Vehículo de Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'counterpart_vehicle_back',
		section: PhotoSection.COUNTERPARTS,
		placeholder: CarBack,
		selected: false,
		description: 'Foto Trasera del Vehículo de Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'counterpart_vehicle_front',
		section: PhotoSection.COUNTERPARTS,
		placeholder: CarFront,
		selected: false,
		description: 'Foto Delantera del Vehículo de Contraparte',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
	},
	{
		id: 'counterpart_plate',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Plate,
		selected: false,
		description: 'Foto del Placa del Vehículo de Contraparte',
		required: true,
		validations: [Validation.EXTRACT_PLATE],
		validationResults: {},
	},
	{
		id: 'counterpart_damage_1',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Damage,
		selected: false,
		description: 'Foto Daños 1 del Vehículo de Contraparte',
		required: true,
		validations: [],
		validationResults: {},
	},
	{
		id: 'counterpart_damage_2',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Damage,
		selected: false,
		description: 'Foto Daños 2 del Vehículo de Contraparte',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'counterpart_damage_3',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Damage,
		selected: false,
		description: 'Foto Daños 3 del Vehículo de Contraparte',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'counterpart_damage_4',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Damage,
		selected: false,
		description: 'Foto Daños 4 del Vehículo de Contraparte',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'counterpart_damage_5',
		section: PhotoSection.COUNTERPARTS,
		placeholder: Damage,
		selected: false,
		description: 'Foto Daños 5 del Vehículo de Contraparte',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'counterpart_license',
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: License,
		selected: false,
		description: 'Foto de Licencia de la Contraparte',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'counterpart_registration',
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: VehicleRegistration,
		selected: false,
		description: 'Foto de Registro Único Vehícular de la Contraparte',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'counterpart_id',
		section: PhotoSection.COUNTERPARTS_DOCUMENTS,
		placeholder: Identification,
		selected: false,
		description: 'Foto de Cédula de la Contraparte',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
	},
	{
		id: 'others_1',
		section: PhotoSection.OTHERS,
		placeholder: Other,
		selected: false,
		description: 'Otros 1',
		required: false,
		validations: [],
		validationResults: {},
	},
	{
		id: 'others_2',
		section: PhotoSection.OTHERS,
		placeholder: Other,
		selected: false,
		description: 'Otros',
		required: false,
		validations: [],
		validationResults: {},
	},
];
